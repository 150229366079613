<template>
  <div class="row">
    <div class="col-12">
      <div class="card card-custom gutter-b example example-compact">
        <div
          class="card-header d-flex align-items-center justify-content-between"
        >
          <div class="card-title">
            <h3 class="card-label">Sug'urta registratsiyasi</h3>
          </div>
        </div>
        <div class="card-body">
          <div class="d-flex">
            <div class="col-4 pl-0">
              <v-autocomplete
                :items="truckList"
                v-model="truck"
                :disabled="this.$route.params.id !== undefined"
                :error-messages="truckErrors"
                item-text="name"
                item-value="id"
                label="Mashina raqami"
                :loading="isLoading"
                dense
                outlined
                @input="$v.truck.$touch()"
                @blur="$v.truck.$touch()"
              ></v-autocomplete>
            </div>
            <div class="col-4 pl-0">
              <v-text-field
                :error-messages="reg_numberErrors"
                v-model="reg_number"
                label="Reg raqami"
                dense
                outlined
                @input="$v.reg_number.$touch()"
                @blur="$v.reg_number.$touch()"
              ></v-text-field>
            </div>
            <div class="col-4">
              <v-text-field
                :error-messages="given_byErrors"
                v-model="given_by"
                label="Berilgan joy"
                outlined
                dense
                @input="$v.given_by.$touch()"
                @blur="$v.given_by.$touch()"
              ></v-text-field>
            </div>
          </div>
          <div class="d-flex">
            <div class="col-4 pl-0">
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :error-messages="reg_dateErrors"
                    label="Boshlanish sanasi"
                    :value="reg_date | dateFilter"
                    append-icon="event"
                    readonly
                    v-bind="attrs"
                    dense
                    v-on="on"
                    outlined
                    @input="$v.reg_date.$touch()"
                    @blur="$v.reg_date.$touch()"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="reg_date"
                  @input="menu2 = false"
                ></v-date-picker>
              </v-menu>
            </div>
            <div class="col-4">
              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :error-messages="expiresErrors"
                    append-icon="event"
                    :value="expires | dateFilter"
                    label="Tugash sanasi"
                    readonly
                    v-bind="attrs"
                    dense
                    v-on="on"
                    outlined
                    @input="$v.expires.$touch()"
                    @blur="$v.expires.$touch()"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="expires"
                  @input="menu = false"
                ></v-date-picker>
              </v-menu>
            </div>
          </div>

          <div class="d-flex justify-content-start border-top pt-5">
            <div class="mr-2">
              <v-btn
                color="success"
                class="
                  btn btn-light-primary
                  font-weight-bold
                  text-uppercase
                  px-9
                  py-4
                "
                @click="save"
                :disabled="newInsurance"
              >
                <i v-if="newInsurance" class="el-icon-loading"></i> Saqlash
              </v-btn>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style></style>

<script>
import Swal from 'sweetalert2'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
// import debounce from "debounce";
import { required } from 'vuelidate/lib/validators'
export default {
  data() {
    return {
      newInsurance: false,
      menu: false,
      memu2: false,
      reg_date: '',
      expires: '',
      given_by: '',
      reg_number: '',
      truck: ''
    }
  },
  validations: {
    expires: {
      required
    },
    given_by: {
      required
    },
    reg_number: {
      required
    },
    truck: {
      required
    },
    reg_date: {
      required
    }
  },
  created() {
    this.$store.dispatch('getTruckLists')
  },
  computed: {
    truckList() {
      const data = this.$store.state.requests.allTruckList
      data.forEach((x) => {
        x.name = x.reg_number_region + ' ' + x.reg_number
      })
      return data
    },
    isLoading() {
      return this.$store.state.requests.isLoading
    },
    truckErrors() {
      const errors = []
      if (!this.$v.truck.$dirty) return errors

      !this.$v.truck.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    reg_numberErrors() {
      const errors = []
      if (!this.$v.reg_number.$dirty) return errors

      !this.$v.reg_number.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    given_byErrors() {
      const errors = []
      if (!this.$v.given_by.$dirty) return errors

      !this.$v.given_by.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    expiresErrors() {
      const errors = []
      if (!this.$v.expires.$dirty) return errors

      !this.$v.expires.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    reg_dateErrors() {
      const errors = []
      if (!this.$v.reg_date.$dirty) return errors

      !this.$v.reg_date.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    }
  },
  mounted() {
    if (this.$route.params.id !== undefined) {
      this.truck = parseInt(this.$route.params.id, 10)
    }
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Sug'urta registratsiyasi" }
    ])
  },
  methods: {
    save() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR'
        Swal.fire({
          title: 'Xato',
          text: "Ma'lumotlar yuborilmadi!",
          icon: 'error',
          confirmButtonClass: 'btn btn-secondary'
        })
      } else {
        this.submitStatus = 'PENDING'
        this.submitStatus = 'OK'
        const data = {
          reg_date: this.reg_date,
          truck: this.truck,
          given_by: this.given_by,
          reg_number: this.reg_number,
          expires: this.expires
        }
        this.newInsurance = true
        this.$store
          .dispatch('creategetInsuarences', data)
          .then(() => {
            this.newInsurance = false
            this.truck = ''
            this.permitted = ''
            this.reg_number = ''
            this.expires = ''
            this.$v.$reset()
          })
          .catch((err) => {
            this.newInsurance = false
            console.error(err)
          })
      }
    }
  }
}
</script>
